<template>
  <default-loader class="vms-loader__visdom-shield--full"></default-loader>
</template>

<script>
import firebase from '@plugins/firebase'
import DefaultLoader from '@components/loaders/default-loader'
import NProgress from '@plugins/nprogress'

export default {
  components: {
    DefaultLoader,
  },

  props: {
    firebaseToken: {
      type: String,
      required: true,
      default: '',
    },

    redirect: {
      type: String,
      required: true,
      default: '',
    },
  },

  computed: {
    /**
     * Decode redirect URL from base64
     * @function
     */
    redirectUrl() {
      return Buffer.from(decodeURIComponent(this.redirect), 'base64').toString(
        'ascii'
      )
    },

    /**
     * Resolve route.
     * Will default to catch-all in case none found.
     * @function
     */
    resolvedRoute() {
      return this.$router.resolve(this.redirectUrl)
    },
  },

  created() {
    // Remove query string from URL for security.
    window.history.replaceState({}, null, this.$route.path)

    // Sign in.
    this.signInWithAccessToken()
  },

  methods: {
    signInWithAccessToken() {
      const vm = this

      NProgress.start()

      firebase
        .auth()
        .signInWithCustomToken(vm.firebaseToken)
        .then((userCredential) => {
          userCredential.user.getIdToken(true).then((accessToken) => {
            const commitPayload = {
              uid: userCredential.user.uid,
              email: userCredential.user.email,
              displayName: userCredential.user.displayName,
              photoUrl: userCredential.user.photoUrl,
              accessToken,
            }

            vm.$store.dispatch('auth/updateFirebaseUserInfo', commitPayload)

            vm.$router.push(vm.resolvedRoute.route).catch(() => {})
          })
        })
        .catch(() => {
          vm.$router.push({
            name: 'error.404',
          })
        })
    },
  },
}
</script>
